<script>
import aclModuleClient from '@/core/acl-module-client';

export default {
  name: 'AclListRegions',
  props: {
    currentTab: { type: Number, required: true },
    regions: { type: Object, required: true },
  },
  data: () => ({
    filters: {
      page: 1,
      limit: 50,
      // search: '',
    },
    new_region: {
      code: null,
    },
  }),
  computed: {
    title() {
      if (this.regions.loading === false) {
        return `Regions (${this.regions.numberOfItems})`;
      }
      return 'Regions';
    },
  },
  methods: {
    addRegion() {
      this.new_region.loading = true;
      return aclModuleClient.post('v1/regions', {
        code: this.new_region.code,
        active: 1,
      })
        .then(() => {
          this.$noty.success('Region added');
          this.new_region.code = null;
          this.$bvModal.hide('modal-add-region');
          this.$emit('region-added');
        })
        .catch(e => {
          this.$noty.error('Error adding region');
          console.error(e);
        })
        .finally(() => {
          this.new_region.loading = false;
        });
    },
    deleteRegion(id, title) {
      return this.$bvModal.msgBoxConfirm(`Delete region ${title}`, { title: 'Confirmation' })
        .then(value => {
          if (!value) {
            return;
          }
          aclModuleClient.delete(`v1/regions/${id}`)
            .then(() => {
              this.$noty.success('Region deleted');
              this.$emit('region-deleted');
            })
            .catch(e => {
              this.$noty.error('Error deleting region');
              console.error(e);
            })
            .finally(() => {
              this.new_region.loading = false;
            });
        })
        .catch(() => {
          this.$noty.error('Cannot open confirmation dialog');
        });
    },
  },
};
</script>
<template>
  <b-tab :title="title">
    <b-row class="mb-3">
      <b-col cols="12">
        <b-button variant="primary" class="float-right" v-b-modal.modal-add-region>
          <feather type="plus"></feather>
          Add new region
        </b-button>

        <b-modal id="modal-add-region" title="Add new region">
          <b-form-group label="Role">
            <b-form-input v-model="new_region.code" placeholder="code" required></b-form-input>
          </b-form-group>
          <template #modal-footer="{ hide }">
            <b-button variant="secondary" @click="hide('cancel')">Cancel</b-button>
            <b-button variant="primary" @click="addRegion">Save</b-button>
          </template>
        </b-modal>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-table :fields="regions.fields" :items="regions.items" :busy="regions.loading"
                 outlined sticky-header="800px" striped empty-text="No regions found">
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(active)="data">
            <b-badge variant="success" v-if="data.item.active">Active</b-badge>
            <b-badge variant="danger" v-else>Inactive</b-badge>
          </template>
          <template #cell(more)="data">
            <b-button variant="danger" @click="deleteRegion(data.item.id, data.item.code)"><feather type="x"/></b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-tab>
</template>
