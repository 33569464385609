<script>
import aclModuleClient from '@/core/acl-module-client';

export default {
  name: 'AclListApps',
  props: {
    currentTab: { type: Number, required: true },
    regions: { type: Object, required: true },
    apps: { type: Object, required: true },
  },
  data: () => ({
    new_app: {
      id: null,
      loading: false,
      name: null,
      regions: [],
    },
  }),
  computed: {
    regionsOptions() {
      return this.regions.items.map(i => ({ text: i.code, value: i.id }));
    },
    title() {
      if (this.apps.loading === false) {
        return `Apps (${this.apps.numberOfItems})`;
      }
      return 'Apps';
    },
    isNewApp() {
      return !this.new_app.id;
    },
  },
  methods: {
    openModal(app) {
      this.new_app.id = app.id;
      this.new_app.name = app.name;
      this.new_app.regions = app.regions.map(r => r.id);
      this.$bvModal.show('modal-add-app');
    },
    resetModal() {
      this.new_app.id = null;
      this.new_app.name = null;
      this.new_app.regions = [];
      this.$bvModal.hide('modal-add-app');
    },
    addApp() {
      this.new_app.loading = true;
      return aclModuleClient.post('v1/apps', {
        name: this.new_app.name,
        regions: this.new_app.regions,
      })
        .then(() => {
          this.$noty.success('App added');
          this.resetModal();
          this.$emit('app-added');
        })
        .catch(e => {
          this.$noty.error('Error adding app');
          console.error(e);
        })
        .finally(() => {
          this.new_app.loading = false;
        });
    },
    updateApp() {
      this.new_app.loading = true;
      return aclModuleClient.put(`v1/apps/${this.new_app.id}`, {
        name: this.new_app.name,
        regions: this.new_app.regions,
      })
        .then(() => {
          this.$noty.success('App updated');
          this.resetModal();
          this.$emit('app-added');
        })
        .catch(e => {
          this.$noty.error('Error updating app');
          console.error(e);
        })
        .finally(() => {
          this.new_app.loading = false;
        });
    },
    deleteApp(id, title) {
      return this.$bvModal.msgBoxConfirm(`Delete app ${title}`, { title: 'Confirmation' })
        .then(value => {
          if (!value) {
            return;
          }
          aclModuleClient.delete(`v1/apps/${id}`, { data: { active: false } })
            .then(() => {
              this.$noty.success('App deleted');
              this.$emit('app-deleted');
            })
            .catch(e => {
              this.$noty.error('Error deleting app');
              console.error(e);
            })
            .finally(() => {
              this.new_app.loading = false;
            });
        })
        .catch(() => {
          this.$noty.error('Cannot open confirmation dialog');
        });
    },
  },
};
</script>
<template>
  <b-tab :title="title">
    <b-row class="mb-3">
      <b-col cols="12">
        <b-button variant="primary" class="float-right" v-b-modal.modal-add-app>
          <feather type="plus"></feather>
          Add new app
        </b-button>

        <b-modal @hidden="resetModal" id="modal-add-app" :title="isNewApp ? 'Add new app' : `Update app ${new_app.name}`">
          <b-form-group label="Name">
            <b-form-input v-model="new_app.name" placeholder="name" required></b-form-input>
          </b-form-group>

          <b-form-group label="Regions:">
            <b-form-checkbox-group id="checkbox-group-regions" v-model="new_app.regions" :options="regionsOptions"
              name="new-app-regions"></b-form-checkbox-group>
          </b-form-group>

          <template #modal-footer="{ hide }">
            <b-button variant="secondary" @click="resetModal">Cancel</b-button>
            <b-button v-if="isNewApp" variant="primary" @click="addApp">Save</b-button>
            <b-button v-else variant="primary" @click="updateApp">Update</b-button>
          </template>
        </b-modal>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-table :fields="apps.fields" :items="apps.items" :busy="apps.loading"
                 outlined sticky-header="800px" striped empty-text="No apps found">
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(regions)="data">
            <b-badge v-for="region in data.item.regions" :key="region.id" class="mr-1">
              {{ region.code }}
            </b-badge>
          </template>
          <template #cell(active)="data">
            <b-badge variant="success" v-if="data.item.active">Active</b-badge>
            <b-badge variant="danger" v-else>Inactive</b-badge>
          </template>
          <template #cell(more)="data">
            <b-button class="mr-3" variant="success" @click="openModal(data.item)"><feather type="edit-2"/></b-button>
            <b-button variant="danger" @click="deleteApp(data.item.id, data.item.name)"><feather type="x"/></b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-tab>
</template>
