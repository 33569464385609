<script>
import aclModuleClient from '@/core/acl-module-client';

const indexForThisTab = 4;

export default {
  name: 'AclListRoles',
  props: {
    currentTab: { type: Number, required: true },
    apps: { type: Object, required: true },
  },
  data: () => ({
    filters: {
      page: 1,
      limit: 10,
      name: '',
      application: null,
      category: null,
    },
    categories: {
      loading: true,
      items: [],
    },
    roles: {
      loading: true,
      loaded: false,
      numberOfItems: 0,
      items: [],
      fields: [
        { key: 'id', label: '#', sortable: true },
        { key: 'title', label: 'Title', sortable: true },
        { key: 'description', label: 'Description' },
        { key: 'app_name', label: 'App', sortable: true },
        { key: 'category_name', label: 'Category', sortable: true },
        { key: 'more', label: 'More' },
      ],
      sort: {
        sortBy: 'id',
        sortDesc: false,
        keyMap: {
          id: 'id',
          name: 'title',
          app_name: 'app_id',
          category_name: 'category_id',
        },
      },
    },
    new_role: {
      loading: false,
      title: null,
      description: null,
      app: null,
      category: null,
    },
    editingRole: {
      loading: false,
      title: '',
      data: { },
    },
  }),
  beforeMount() {
    this.fetchRoles();
    this.fetchCategories();
  },
  computed: {
    appsOptions() {
      return this.apps.items.map(i => ({ text: i.name, value: i.id }));
    },
    categoriesOptions() {
      return this.categories.items.map(i => ({ text: i.name, value: i.id }));
    },
    title() {
      if (this.roles.loading === false) {
        return `Roles (${this.roles.numberOfItems})`;
      }
      return 'Roles';
    },
    availableRolesApplicationFiltersOptions() {
      return [
        { text: 'All applications', value: null },
        ...this.appsOptions,
      ];
    },
    availableRolesCategoriesFiltersOptions() {
      return [
        { text: 'All categories', value: null },
        ...this.categoriesOptions,
      ];
    },
  },
  watch: {
    currentTab(value) {
      if (indexForThisTab === value && this.roles.loaded === false) {
        this.fetchRoles();
      }
    },
  },
  methods: {
    fetchRoles(newPage = 1) {
      this.filters.page = newPage;
      this.roles.loading = true;
      const { sort } = this.roles;

      let search = [];
      if (this.filters.name) search.push(`title:${this.filters.name}`);
      if (this.filters.application) search.push(`app_id:${this.filters.application}`);
      if (this.filters.category) search.push(`category_id:${this.filters.category}`);
      search = search.join(',');

      const params = { page: this.filters.page, limit: this.filters.limit };
      if (search !== '') {
        params.search = search;
      }

      const sortKey = sort.keyMap[sort.sortBy] || sort.sortBy;
      if (sort.sortBy) {
        params.sort = sortKey ? `${sortKey}:${sort.sortDesc ? 'desc' : 'asc'}` : null;
      }

      return aclModuleClient.get('v1/roles', { params })
        .then(response => {
          this.roles.numberOfItems = response.data.total;
          this.roles.items = response.data.data;
        })
        .catch(e => {
          this.$noty.error('Error loading roles');
          console.error(e);
        })
        .finally(() => {
          this.roles.loading = false;
          this.roles.loaded = true;
        });
    },
    fetchCategories() {
      this.categories.loading = true;
      return aclModuleClient.get('v1/categories')
        .then(response => {
          this.categories.items = response.data.data;
        })
        .catch(e => {
          this.$noty.error('Error loading categories');
          console.error(e);
        })
        .finally(() => {
          this.categories.loading = false;
        });
    },
    addRole() {
      this.new_role.loading = true;
      return aclModuleClient.post('v1/roles', {
        title: this.new_role.title,
        description: this.new_role.description,
        app_id: this.new_role.app,
        category_id: this.new_role.category,
      })
        .then(() => {
          this.$noty.success('Role added');
          this.new_role = {
            title: null,
            description: null,
            app: null,
            category: null,
          };
          this.fetchRoles();
          this.$bvModal.hide('modal-add-role');
        })
        .catch(e => {
          this.$noty.error('Error adding role');
          console.error(e);
        })
        .finally(() => {
          this.new_role.loading = false;
        });
    },
    deleteRole(id, title) {
      return this.$bvModal.msgBoxConfirm(`Delete role ${title}`, { title: 'Confirmation' })
        .then(value => {
          if (!value) {
            return;
          }
          aclModuleClient.delete(`v1/roles/${id}`, { data: { active: false } })
            .then(() => {
              this.$noty.success('Role deleted');
              this.fetchRoles(this.filters.page);
            })
            .catch(e => {
              this.$noty.error('Error deleting role');
              console.error(e);
            })
            .finally(() => {
              this.new_role.loading = false;
            });
        })
        .catch(() => {
          this.$noty.error('Cannot open confirmation dialog');
        });
    },
    sortRoles({ sortBy, sortDesc }) {
      this.roles.sort.sortBy = sortBy;
      this.roles.sort.sortDesc = sortDesc;
      this.fetchRoles();
    },
    applyRolesFilters() {
      this.roles.items = [];
      this.filters.page = 1;
      this.fetchRoles();
    },
    editRole(role) {
      // eslint-disable-next-line no-undef
      this.editingRole.data = structuredClone(role);
      this.editingRole.title = role.title;
      this.$refs['modal-edit-role'].show();
    },
    confirmUpdateRole() {
      if (!this.editingRole.data.title || this.editingRole.data.title === '') {
        this.$noty.error('Role title cannot not be empty');
        return Promise.resolve();
      }

      this.editingRole.loading = true;

      const modalHideEventHandler = e => ((e.componentId === 'modal-edit-role') && e.preventDefault());
      this.$root.$on('bv::modal::hide', modalHideEventHandler);

      return aclModuleClient
        .put(`v1/roles/${this.editingRole.data.id}`, {
          title: this.editingRole.data.title,
          description: this.editingRole.data.description,
          category_id: this.editingRole.data.category_id,
          app_id: this.editingRole.data.app_id,
        })
        .then(() => {
          this.fetchRoles(this.filters.page);
          this.$noty.success('Role updated successfully');
          this.$root.$off('bv::modal::hide', modalHideEventHandler);
          this.$refs['modal-edit-role'].hide();
        })
        .catch(error => {
          console.error(error);
          this.$noty.error('Error trying to update role');
          this.$root.$off('bv::modal::hide', modalHideEventHandler);
        })
        .finally(() => {
          setTimeout(() => {
            this.editingRole.loading = false;
          }, 500);
        });
    },
  },
};
</script>
<template>
  <b-tab :title="title">
    <b-row class="mb-3">
      <b-col cols="12">
        <b-button variant="primary" class="float-right" v-b-modal.modal-add-role>
          <feather type="plus"></feather>
          Add new role
        </b-button>

        <b-button
          class="mr-4 float-right"
          variant="outline-secondary"
          title="Show filters"
          v-b-tooltip:hover
          v-b-toggle.collapse-available-roles-filters>
          Filters
          <feather type="filter"></feather>
        </b-button>

        <!-- #region Role creation modal -->
        <b-modal id="modal-add-role" title="Add new role">
          <b-container class="modal-body p-0" fluid>
            <b-form-row>
              <b-col>
                <b-form-group label="Title">
                  <b-form-input v-model="new_role.title" placeholder="Enter role title" required></b-form-input>
                </b-form-group>

                <b-form-group label="Description">
                  <b-form-textarea id="textarea" v-model="new_role.description" placeholder="Description" rows="3" />
                </b-form-group>

                <b-form-group label="App">
                  <b-form-select
                    v-model="new_role.app"
                    :options="appsOptions" required/>
                </b-form-group>

                <b-form-group label="Category">
                  <b-form-select
                    v-model="new_role.category"
                    :options="categoriesOptions" required/>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-container>

          <template #modal-footer="{ hide }">
            <b-button variant="secondary" @click="hide('cancel')">Cancel</b-button>
            <b-button variant="primary" @click="addRole">Save</b-button>
          </template>
        </b-modal>
        <!-- #endregion -->

      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-row>
          <b-col>
            <b-collapse id="collapse-available-roles-filters">
              <b-card class="mb-3" bg-variant="light" no-body>
                <b-card-body>
                  <b-card-title class="">
                    Filters
                  </b-card-title>

                  <b-form @submit.prevent>
                    <b-form-row>
                      <b-col>
                        <b-form-group id="arf-group-name" label="Name" label-for="arf-name">
                          <b-form-input
                            id="arf-name"
                            placeholder="Enter role name"
                            v-model="filters.name" />
                        </b-form-group>
                      </b-col>

                      <b-col>
                        <b-form-group id="arf-group-application" label="Application" label-for="arf-application">
                          <b-form-select
                            id="arf-application"
                            v-model="filters.application"
                            :options="availableRolesApplicationFiltersOptions" />
                        </b-form-group>
                      </b-col>

                      <b-col>
                        <b-form-group id="arf-group-category" label="Category" label-for="arf-category">
                          <b-form-select
                            id="arf-category"
                            v-model="filters.category"
                            :options="availableRolesCategoriesFiltersOptions" />
                        </b-form-group>
                      </b-col>

                    </b-form-row>

                    <b-form-row>
                      <b-col class="d-flex justify-content-end">
                        <b-button
                          prevent
                          type="submit"
                          variant="primary"
                          :disabled="roles.loading"
                          @click.prevent="applyRolesFilters">
                          Apply
                          <b-spinner v-if="false" class="align-middle ml-2" small/>
                          <feather v-else type="filter"></feather>
                        </b-button>
                      </b-col>
                    </b-form-row>

                  </b-form>
                </b-card-body>
              </b-card>
            </b-collapse>
          </b-col>
        </b-row>
        <b-table
          sticky-header="800px"
          empty-text="No roles found"
          :fields="roles.fields"
          :items="roles.items"
          :busy="roles.loading"
          :sort-by.sync="roles.sort.sortBy"
          :sort-desc.sync="roles.sort.sortDesc"
          @sort-changed="sortRoles"
          bordered
          striped
          show-empty>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template #empty>
            <div class="text-center my-2">
              <strong>No roles to show</strong>
            </div>
          </template>

          <template #cell(more)="data">
            <b-button variant="success" class="mr-3" title="Edit this role" @click="editRole(data.item)" v-b-tooltip:hover>
              <feather type="edit-2" />
            </b-button>
            <b-button variant="danger" title="Remove this role" @click="deleteRole(data.item.id, data.item.title)" v-b-tooltip:hover>
              <feather type="x" />
            </b-button>
          </template>
        </b-table>

      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-pagination
          align="center"
          class="my-0"
          :value="filters.page"
          :total-rows="roles.numberOfItems"
          :per-page="filters.limit"
          @change="fetchRoles">
        </b-pagination>
      </b-col>
    </b-row>

    <!-- #region Edit role modal -->
    <b-modal id="modal-edit-role" ref="modal-edit-role">
      <template #modal-title>
        <h5 class="modal-title">
          Edit role - <code>{{ editingRole.title }}</code>
        </h5>
      </template>

      <b-container class="modal-body p-0" fluid>
        <b-form-row>
          <b-col>
            <b-form-group label="Title">
              <b-form-input placeholder="Enter role title" v-model="editingRole.data.title" :disabled="editingRole.loading" />
            </b-form-group>

            <b-form-group label="Description">
              <b-form-textarea
                id="textarea"
                placeholder="Description"
                rows="3"
                v-model="editingRole.data.description"
                :disabled="editingRole.loading" />
            </b-form-group>

            <b-form-group label="App">
              <b-form-select v-model="editingRole.data.app_id" :disabled="editingRole.loading" :options="appsOptions" />
            </b-form-group>

            <b-form-group label="Category">
              <b-form-select v-model="editingRole.data.category_id" :disabled="editingRole.loading" :options="categoriesOptions" />
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-container>

      <template #modal-footer="{ hide }">
        <b-button variant="secondary" :disabled="editingRole.loading" @click="hide('cancel')">
          Cancel
        </b-button>
        <b-button variant="primary" :disabled="editingRole.loading" @click="confirmUpdateRole">
          Update
        </b-button>
      </template>
    </b-modal>
    <!-- #endregion-->

  </b-tab>
</template>
