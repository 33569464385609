<script>
import AclListGroups from '@/views/Acl/List/Groups.vue';
import AclListUsers from '@/views/Acl/List/Users.vue';
import AclListApps from '@/views/Acl/List/Apps.vue';
import AclListRoles from '@/views/Acl/List/Roles.vue';
import AclListRegions from '@/views/Acl/List/Regions.vue';
import aclModuleClient from '@/core/acl-module-client';

export default {
  name: 'AclLists',
  components: {
    AclListGroups, AclListUsers, AclListApps, AclListRegions, AclListRoles,
  },
  data: () => ({
    currentTab: 0,
    regions: {
      loading: true,
      numberOfItems: 0,
      filters: {
        page: 1,
        limit: 50,
      },
      items: [],
      fields: [
        { key: 'id', label: '#' },
        { key: 'code', label: 'Code' },
        { key: 'active', label: 'Active' },
        { key: 'more', label: 'More' },
      ],
    },
    apps: {
      loading: true,
      loaded: false,
      numberOfItems: 0,
      filters: {
        page: 1,
        limit: 50,
      },
      items: [],
      fields: [
        { key: 'id', label: '#' },
        { key: 'name', label: 'Name' },
        { key: 'active', label: 'Active' },
        { key: 'regions', label: 'Regions' },
        { key: 'more', label: 'More' },
      ],
    },
  }),
  beforeMount() {
    this.fetchRegions();
    this.fetchApps();
  },
  methods: {
    fetchRegions() {
      this.regions.loading = true;
      return aclModuleClient.get('v1/regions', { params: this.filters })
        .then(response => {
          this.regions.numberOfItems = response.data.total;
          this.regions.items = response.data.data;
        })
        .catch(e => {
          this.$noty.error('Error loading apps');
          console.error(e);
        })
        .finally(() => {
          this.regions.loading = false;
        });
    },
    fetchApps() {
      this.apps.loading = true;
      return aclModuleClient.get('v1/apps', { params: this.apps.filters })
        .then(response => {
          this.apps.numberOfItems = response.data.total;
          this.apps.items = response.data.data;
        })
        .catch(e => {
          this.$noty.error('Error loading apps');
          console.error(e);
        })
        .finally(() => {
          this.apps.loading = false;
          this.apps.loaded = true;
        });
    },
  },
};
</script>
<template>
  <b-container class="p-4">
    <b-row>
      <b-col class="mb-2 d-flex align-items-center justify-content-between"><h4>ACL Configurations</h4></b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-tabs v-model="currentTab" content-class="mt-3" fill>
          <acl-list-groups :currentTab="currentTab"/>
          <acl-list-users :currentTab="currentTab"/>
          <acl-list-apps :currentTab="currentTab" :apps="apps" :regions="regions" @app-deleted="fetchApps" @app-added="fetchApps"/>
          <acl-list-regions :currentTab="currentTab" :regions="regions" @region-deleted="fetchRegions" @region-added="fetchRegions"/>
          <acl-list-roles :currentTab="currentTab" :apps="apps"/>
        </b-tabs>
      </b-col>
    </b-row>
  </b-container>
</template>
