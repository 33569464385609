<script>
import * as R from 'ramda';
import aclModuleClient from '@/core/acl-module-client';

const indexForThisTab = 1;

export default {
  name: 'AclListUsers',
  props: {
    currentTab: { type: Number, required: true },
  },
  data: () => ({
    filters: {
      page: 1,
      limit: 50,
      search: '',
    },
    users: {
      loading: true,
      loaded: false,
      numberOfItems: 0,
      items: [],
      fields: [
        { key: 'id', label: '#' },
        { key: 'email', label: 'Email' },
        { key: 'name', label: 'Name' },
        { key: 'active', label: 'Active' },
        { key: 'strict_data_access', label: 'Strict' },
        { key: 'created_at', label: 'created_at' },

        { key: 'more', label: 'More' },
      ],
    },
    new_user: {
      loading: true,
      title: null,
    },
  }),
  watch: {
    currentTab(value) {
      if (indexForThisTab === value && this.users.loaded === false) {
        this.fetchUsers();
      }
    },
  },
  computed: {
    title() {
      if (this.users.loading === false) {
        return `Users (${this.users.numberOfItems})`;
      }
      return 'Users';
    },
  },
  methods: {
    searchUsers() {
      this.fetchUsers(1);
    },
    addUser() {
      this.new_user.loading = true;
      const names = this.new_user.name.split(' ');
      if (names.length < 2) {
        this.$noty.error('Name must have at least first and last');
        this.new_user.loading = false;
        return;
      }

      const payload = {
        email: this.new_user.email,
        name: this.new_user.name,
        first_name: names[0],
        last_name: names[names.length - 1],
        strict_data_access: this.new_user.strict_data_access,
      };

      if (this.new_user.password) {
        payload.password = this.new_user.password;
      }

      aclModuleClient.post('v1/users', payload)
        .then(() => {
          this.$noty.success('User added');
          this.new_user.email = null;
          this.new_user.name = null;
          this.new_user.password = null;
          this.new_user.strict_data_access = false;
          this.$bvModal.hide('modal-add-user');
          this.fetchUsers(1);
        })
        .catch(e => {
          this.$noty.error('Error adding user');
          console.error(e);
        })
        .finally(() => {
          this.new_user.loading = false;
        });
    },
    fetchUsers(page = 1) {
      this.users.loading = true;
      const filters = R.clone(this.filters);
      filters.page = page;
      if (!filters.search || filters.search === '') {
        delete filters.search;
      } else {
        filters.search = `email:${filters.search}`;
      }
      return aclModuleClient.get('v1/users', { params: filters })
        .then(response => {
          this.users.numberOfItems = response.data.total;
          this.users.items = response.data.data;
        })
        .catch(e => {
          this.$noty.error('Error loading users');
          console.error(e);
        })
        .finally(() => {
          this.users.loading = false;
          this.users.loaded = true;
        });
    },
  },
};
</script>
<template>
  <b-tab :title="title">
    <b-row class="mb-3">
      <b-col cols="12">
        <b-form inline class="float-left" onsubmit="return false;">
          <b-form-input id="input-acl-group-search" v-model="filters.search" type="text" placeholder="search email ..."></b-form-input>
          <b-button variant="primary" class="ml-3" @click="searchUsers">
            <feather type="search"></feather>
          </b-button>
        </b-form>

        <b-button variant="primary" class="float-right" v-b-modal.modal-add-user>
          <feather type="plus"></feather>
          Add User
        </b-button>

        <b-modal id="modal-add-user" title="Add new user">
          <b-form-group label="Email">
            <b-form-input v-model="new_user.email" type="email" placeholder='email' required></b-form-input>
          </b-form-group>
          <b-form-group label="Name">
            <b-form-input v-model="new_user.name" placeholder='name' required></b-form-input>
          </b-form-group>
          <b-form-group label="Password">
            <b-form-input v-model="new_user.password" type="password" placeholder='password' required></b-form-input>
          </b-form-group>
          <b-form-group label="Strict data access">
            <b-form-checkbox v-model="new_user.strict_data_access" switch></b-form-checkbox>
          </b-form-group>
          <template #modal-footer="{ hide }">
            <b-button variant="secondary" @click="hide('cancel')">Cancel</b-button>
            <b-button variant="primary" @click="addUser">Save</b-button>
          </template>
        </b-modal>

      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-table :fields="users.fields" :items="users.items" :busy="users.loading"
                 outlined sticky-header="800px" striped empty-text="No users found">
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(active)="data">
            <b-badge variant="success" v-if="data.item.active">Active</b-badge>
            <b-badge variant="danger" v-else>Inactive</b-badge>
          </template>
          <template #cell(strict_data_access)="data">
            <b-badge variant="success" v-if="data.item.strict_data_access">Yes</b-badge>
            <b-badge variant="danger" v-else>No</b-badge>
          </template>
          <template #cell(more)="data">
            <b-button variant="success" :to="`/acl/users/${data.item.uuid}`"><feather type="edit-2"/></b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-pagination align="center" v-model="filters.page" :total-rows="users.numberOfItems"
                      :per-page="filters.limit" class="my-0" @change="fetchUsers">
        </b-pagination>
      </b-col>
    </b-row>
  </b-tab>
</template>
