<script>
import * as R from 'ramda';
import aclModuleClient from '@/core/acl-module-client';

export default {
  name: 'AclListGroups',
  props: {
    currentTab: { type: Number, required: true },
  },
  data: () => ({
    filters: {
      page: 1,
      limit: 10,
      search: '',
    },
    groups: {
      loading: true,
      numberOfItems: 0,
      items: [],
      fields: [
        { key: 'id', label: '#', sortable: true },
        { key: 'title', label: 'Name', sortable: true },
        { key: 'description', label: 'Description' },
        { key: 'active', label: 'Active', sortable: true },
        { key: 'more', label: 'More' },
      ],
      sort: {
        sortBy: 'id',
        sortDesc: false,
      },
    },
    new_group: {
      loading: true,
      title: null,
      description: null,
    },
  }),
  beforeMount() {
    this.fetchGroups();
  },
  computed: {
    title() {
      if (this.groups.loading === false) {
        return `Groups (${this.groups.numberOfItems})`;
      }
      return 'Groups';
    },
  },
  methods: {
    searchGroups() {
      this.fetchGroups(1);
    },
    addGroup() {
      this.new_group.loading = true;
      return aclModuleClient.post('v1/groups', {
        title: this.new_group.title,
        description: this.new_group.description,
      })
        .then(() => {
          this.$noty.success('Group added');
          this.new_group.title = null;
          this.$bvModal.hide('modal-add-group');
          this.fetchGroups(1);
        })
        .catch(e => {
          this.$noty.error('Error adding group');
          console.error(e);
        })
        .finally(() => {
          this.new_group.loading = false;
        });
    },
    fetchGroups(page = 1) {
      this.groups.loading = true;
      const filters = R.clone(this.filters);
      filters.page = page;
      const { sort } = this.groups;
      if (!filters.search || filters.search === '') {
        delete filters.search;
      } else {
        filters.search = `title:${filters.search}`;
      }

      if (sort.sortBy) {
        filters.sort = `${sort.sortBy}:${sort.sortDesc ? 'desc' : 'asc'}`;
      }

      return aclModuleClient.get('v1/groups', { params: filters })
        .then(response => {
          this.groups.numberOfItems = response.data.total;
          this.groups.items = response.data.data;
        })
        .catch(e => {
          this.$noty.error('Error loading groups');
          console.error(e);
        })
        .finally(() => {
          this.groups.loading = false;
        });
    },
    sortGroups({ sortBy, sortDesc }) {
      this.groups.sort.sortBy = sortBy;
      this.groups.sort.sortDesc = sortDesc;
      this.fetchGroups();
    },
  },
};
</script>
<template>
  <b-tab :title="title">
    <b-row class="mb-3">
      <b-col cols="12">
        <b-form inline class="float-left" onsubmit="return false;">
          <b-form-input id="input-acl-group-search" v-model="filters.search" type="text" placeholder="search ..."></b-form-input>
          <b-button variant="primary" class="ml-3" @click="searchGroups">
            <feather type="search"></feather>
          </b-button>
        </b-form>

        <b-button variant="primary" class="float-right" v-b-modal.modal-add-group>
          <feather type="plus"></feather>
          Add new group
        </b-button>

        <b-modal id="modal-add-group" title="Add new group">
          <b-container class="modal-body p-0" fluid>
            <b-form-row>
              <b-col>
                <b-form-group label="Title">
                  <b-form-input v-model="new_group.title" placeholder="Title" required></b-form-input>
                </b-form-group>

                <b-form-group label="Description">
                  <b-form-textarea id="textarea" v-model="new_group.description" placeholder="Description" rows="3" />
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-container>

          <template #modal-footer="{ hide }">
            <b-button variant="secondary" @click="hide('cancel')">Cancel</b-button>
            <b-button variant="primary" @click="addGroup">Save</b-button>
          </template>
        </b-modal>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-table
          :fields="groups.fields"
          :items="groups.items"
          :busy="groups.loading"
          outlined
          sticky-header="800px"
          striped
          empty-text="No groups found"
          :sort-by.sync="groups.sort.sortBy"
          :sort-desc.sync="groups.sort.sortDesc"
          @sort-changed="sortGroups">
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(active)="data">
            <b-badge variant="success" v-if="data.item.active">Active</b-badge>
            <b-badge variant="danger" v-else>Inactive</b-badge>
          </template>

          <template #cell(more)="data">
            <b-button variant="success" :to="`/acl/groups/${data.item.id}`"><feather type="edit-2"/></b-button>
          </template>

        </b-table>

      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-pagination align="center" v-model="filters.page" :total-rows="groups.numberOfItems"
                      :per-page="filters.limit" class="my-0" @change="fetchGroups">
        </b-pagination>
      </b-col>
    </b-row>
  </b-tab>
</template>
